import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { createMetaManager } from "vue-meta";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "@/assets/scss/index.scss";
import tabulator from "@/components/tabulator/TabulatorComponent.vue";
import modal from "@/components/modal/modalComponent.vue";
const pinia = createPinia();
const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(createMetaManager())
  .component("tabulator", tabulator)
  .component("modal", modal);
app.mount("#app");
