import { defineStore } from "pinia";
export const useLoading = defineStore("Loading", {
  state: () => ({
    loading: false,
  }),
  getters: {
    GetLoading(state) {
      return state.loading;
    },
  },
  actions: {
    async SetLoading(loading) {
      this.loading = loading;
    },
  },
});
