import { axiosInstance } from "../base";

export default {
  login(data) {
    return axiosInstance.post("api/auth/login/", data);
  },
  logOut() {
    return axiosInstance.delete("api/auth/logout/");
  },
  user(data) {
    return axiosInstance.post("api/auth/", data);
  },
  currentUser() {
    return axiosInstance.get("api/current/");
  },
};
