import axios from "axios";
import { useAuth } from "@/store/auth";
const baseURL = "https://qrdocode.herokuapp.com/";

export const axiosInstance = axios;

axiosInstance.interceptors.request.use(function (config) {
  const token = useAuth().GetToken;
  config.url = baseURL + config.url;
  if (config.url.includes("api/auth/login/")) {
    return config;
  }
  config.headers.Authorization = `token ${token}`;
  return config;
});
