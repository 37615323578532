<template>
  <a
    ref="enlaceModal"
    href="javascript:;"
    data-bs-toggle="modal"
    data-bs-target="#ModalQr"
  ></a>
  <div
    id="ModalQr"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">Código QR Generado</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center">
            <img :src="urlImage" alt="" />
          </div>
        </div>
        <div class="modal-footer">
          <a :href="urlImage" download type="button" class="btn btn-primary"
            >Descargar</a
          >
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Hecho
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { ref } = require("@vue/reactivity");

/* eslint-disable */
const props = defineProps({
  urlImage: {
    type: String,
    default: "",
  },
});
const enlaceModal = ref();
const showModal = () => {
  enlaceModal.value.click();
};
defineExpose({
  showModal,
});
</script>

<style lang="scss" scoped></style>
