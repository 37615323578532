import { createRouter, createWebHistory } from "vue-router";
import { useAuth } from "@/store/auth.js";
const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/",
    component: () => import("@/components/navigation/navigationComponent.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: "/qr-url",
        name: "qr-url",
        component: () => import("@/views/QrUrl.vue"),
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/UsersView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// validacion de autenticacion por session cookie
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    await useAuth().CurrentUser();
    if (useAuth().GetToken === "" || useAuth().GetToken === undefined) {
      if (useAuth().GetToken === "" || useAuth().GetToken === undefined) {
        return next({ path: "/login" });
      }
    }
  }
  next();
});

export default router;
