import Swal from "sweetalert2/dist/sweetalert2.min.js";
export const swalSuccess = (message) => {
  Swal.fire({
    position: "center",
    icon: "success",
    title: message,
    showConfirmButton: false,
    timer: 1500,
  });
};

export const swalError = (message) => {
  Swal.fire({
    position: "center",
    icon: "error",
    title: message,
    showConfirmButton: false,
    timer: 4500,
  });
};

export const swalDelete = (Object, EliminarFunction, limpiarFormulario) => {
  Swal.fire({
    title: `Esta seguro que desea eliminar el registro ${Object.nombre} ?`,
    text: "No podra revertir esta accion!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, eliminar!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await EliminarFunction();
        Swal.fire(
          "Eliminado!",
          "El registro se elimino Correctamente",
          "success"
        );
      } catch (error) {
        Swal.fire("Error!", "El registro no se elimino Correctamente", "error");
      }
    }
    if (result.isDismissed) {
      limpiarFormulario();
    }
  });
};
