import { defineStore } from "pinia";
import { useLoading } from "@/store/loading.js";
import { swalError, swalSuccess } from "@/components/swal/index";
import todoAuth from "@/apiAccess/auth/todoAuth.js";
export const useAuth = defineStore("Auth", {
  state: () => ({
    loading: useLoading(),
    user: {
      email: "",
      username: "",
      role: "",
      nombre: "",
    },
    token:
      sessionStorage.getItem("token") !== null
        ? sessionStorage.getItem("token")
        : "",
  }),
  getters: {
    GetUser(state) {
      return state.user;
    },
    GetToken(state) {
      return state.token;
    },
  },
  actions: {
    async Login(user, router) {
      try {
        this?.loading?.SetLoading?.(true);
        const response = await todoAuth.login(user);
        this.token = response.data.token;
        sessionStorage.setItem("token", this.token);
        swalSuccess("Bienvenido");
        router.push("/");
      } catch (error) {
        console.log(error);
        swalError("Usuario o contraseña incorrectos");
      } finally {
        this?.loading?.SetLoading?.(false);
      }
    },
    async SignUp() {
      try {
        this?.loading?.SetLoading?.(true);
        swalSuccess("Usuario creado correctamente");
      } catch (error) {
        swalError("Usuario o contraseña incorrectos");
      } finally {
        this?.loading?.SetLoading?.(false);
      }
    },
    async LogOut() {
      try {
        this?.loading?.SetLoading?.(true);
        await todoAuth.logOut();
        sessionStorage.removeItem("token");
        swalSuccess("Se cerro la sesion");
        window.location.reload();
      } catch (error) {
        swalError("No se pudo cerrar la sesion");
      } finally {
        this?.loading?.SetLoading?.(false);
      }
    },
    async CurrentUser() {
      try {
        this?.loading?.SetLoading?.(true);
        const response = await todoAuth.currentUser();
        this.user = response.data[0];
      } catch (error) {
        sessionStorage.removeItem("token");
      } finally {
        this?.loading?.SetLoading?.(false);
      }
    },
  },
});
