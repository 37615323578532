<template>
  <div>
    <div>{{ title }}</div>
    <div ref="tableRef"></div>
    <slot name="footer"></slot>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator_bootstrap5.min.css";
import todoPeticiones from "@/apiAccess/peticiones/todoPeticiones.js";
const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },
  url: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
});
const tableRef = ref();
const tabulator = ref();
const peticionapi = async (url, config, params) => {
  return new Promise(function (resolve, reject) {
    todoPeticiones
      .get(url, params)
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const initTabulator = () => {
  tabulator.value = new Tabulator(tableRef.value, {
    ajaxURL: props.url,
    ajaxRequestFunc: peticionapi,
    printAsHtml: true,
    printStyled: true,
    paginationSize: 10,
    progressiveLoad: "scroll",
    progressiveLoadScrollMargin: 100,
    height: "311px",
    layout: "fitColumns",
    responsiveLayout: "collapse",
    placeholder: "No matching records found",
    columns: props.columns,
  });
};
onMounted(() => {
  initTabulator();
});
defineExpose({
  tabulatorVariable: tabulator,
});
</script>
