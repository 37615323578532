import { axiosInstance } from "../base";

export default {
  get(url, params) {
    return axiosInstance.get(url, params);
  },
  post(url, data) {
    return axiosInstance.post(url, data);
  },
  patch(url, data) {
    return axiosInstance.patch(url, data);
  },
  delete(url) {
    return axiosInstance.delete(url);
  },
  deleteUser(url, data) {
    return axiosInstance.delete(url, data);
  },
};
